.footer {
  display: flex;
  align-items: center;
  max-width: calc(100% - #{$gap/3});
  padding-top: $gap-s;
  margin: 200px $gap $gap $gap;
  border-top: 1px solid #E8E8E8;

  
  > p {
    margin: 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    > a {
      font-family: $font-heading;
      text-decoration: none;

      &:hover {
        color: $color-pink;
      }
    }
  }

  .navigation-footer {
    display: flex;
    align-self: flex-start;
    margin-right: 0;
    margin-left: auto;

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 14px;

      li {
        > a {
          padding: 0 $gap/5;
          color: $color-dark;
          text-transform: uppercase;
          text-decoration: none;

          &:hover {
            color: $color-blue;
          }

          &.active {
            font-family: "Manrope Bold";
            color: #333;
          }
        }
      }
    }
  }

  @include respond-to("tablet") {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: $gap;

    .navigation-footer {
      display: flex;
      margin: $gap 0;
    }
  }
}
