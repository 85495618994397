.header {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding-right: $gap;
  padding-left: $gap;
  margin: 3vw auto;
  margin-right: $gap/6;
    margin-left: $gap/6;

  .brand {
    font-family: "Manrope Bold";

    @include respond-to("tablet") {
      margin-left: 0;
      margin-right: auto;
      z-index: 999;
    }

    > img {
      width: 80px;
      height: 80px;
    }
  }

  .navigation {
    display: flex;
    margin-right: 0;
    margin-left: auto;

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 16px;

      li {
        > a {
          text-transform: uppercase;
          padding: 0 $gap/5;
          color: $color-dark;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          &.active {
            font-family: $font-extra-bold;            
          }
        }
      }
    }

    
  }

  #mob-trigger {
    display: none;
    position: absolute;
    right: 15px;
    top: 35px;
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M3.5,7 C3.22385763,7 3,6.77614237 3,6.5 C3,6.22385763 3.22385763,6 3.5,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 C21,6.77614237 20.7761424,7 20.5,7 L3.5,7 Z M3.5,12 C3.22385763,12 3,11.7761424 3,11.5 C3,11.2238576 3.22385763,11 3.5,11 L20.5,11 C20.7761424,11 21,11.2238576 21,11.5 C21,11.7761424 20.7761424,12 20.5,12 L3.5,12 Z M3.5,17 C3.22385763,17 3,16.7761424 3,16.5 C3,16.2238576 3.22385763,16 3.5,16 L20.5,16 C20.7761424,16 21,16.2238576 21,16.5 C21,16.7761424 20.7761424,17 20.5,17 L3.5,17 Z'/%3E%3C/svg%3E");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 999;

    @include respond-to("tablet") {
      display: flex;
    }

    &.opened {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512.001 512.001' style='enable-background:new 0 0 512.001 512.001;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
      background-size: 18px;
    }
  }

  @include respond-to("tablet") {
    flex-direction: column;
    margin: $gap auto;

    .navigation {
      display: none;
      
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: 888;
      padding-top: 150px;
      padding-left: $gap;
      
      ul {
        display: flex;
        flex-direction: column;

        li {
          margin-bottom: 35px;

          a {
            font-size: 30px;
          }
        }
      }
      
      &.opened {
        display: flex;
      }
    }
  }
}
