//? set your brand colors

$white: #fff;

$light-grey: #fafafa;
$grey: #d7d7d7;

$black-soft: #444;
$black: #09090e;

$blue: #2c638f;
$pink: #fff0f5;
$yellow: #fffacd;

//? override for project clarity

$color-gray: #E8E8E8; // light gray
$color-dark: #000; // dark

$color-pink: #ffb6b9;
$color-bright: #fae3d9;
$color-pastel: #bbded6;
$color-blue:#8ac6d1;

$gap: 5vw;
$gap-s: 3vw;

$color-primary: $color-pink;
$color-secondary: $color-pastel;
$color-tertiary: $yellow;


$hov-white: #fff;
$hov-gray: #575756;
$hov-pink: #CE9EA8;
$hov-blue: #1C2A39;
