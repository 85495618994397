.swiper-container {
  width: calc(100% - #{$gap});
  height: 50vh;
  //border: 1px dashed tomato;
  margin-left: $gap;
  margin-bottom: $gap;

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    width: calc(50% - #{$gap/2} - #{$gap/6});

    margin-right: $gap/3;
    //border: 1px dashed teal;
    background-color: $color-bright;

    &:last-child {
      margin-right: $gap;
    }

    @include respond-to("tablet") {
      width: calc(100% - #{$gap});
    }
  }

  > .swiper-scrollbar {
      //background-color: red;
      left: 25%;
      bottom: 7px;
      width: calc(50% - #{$gap});
      height: 7px;
      border-radius: 7px;
  }
}
