.content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $gap-s;
  padding-right: $gap;
  padding-left: $gap;

  > h1 {
    margin-top: 0;
    margin-right: $gap/6;
    margin-left: $gap/6;
  }

  .contact-link {
    font-size: 50px;
    color: #ffbec1;
    line-height: 81px;
    margin: 0 0 2em 0;
    display: inline-block;

    @include respond-to("tablet") {
      font-size: 32px;
    }
  }

  .bg-blue {
    background-color: $hov-blue;
  }
  
  //height: 50vh;

  .half {
    position: relative;
    min-width: calc(50% - #{$gap/3});
    height: 50vh;
    margin-bottom: $gap/2;
    //border: 1px dashed teal;
    overflow: hidden;
    margin-right: $gap/6;
    margin-left: $gap/6;
    
    &:nth-of-type(odd) {
    //  margin-right: $gap/6;
    }
    &:nth-of-type(even) {
    //  margin-left: $gap/6;
    }

    .description {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: $gap;
      background-color: $hov-white;
      opacity: 0;
      box-shadow: 0 0 0 1px #fff;
      transition: opacity 0.4s ease;
      z-index: 1;

      > h3 {
        margin-bottom: $gap/4;
      }

      img {
        max-width: 185px;
        height: auto;
      }

      > p {
        margin: 0;
      }

      &.hov-blue {
        background-color: $hov-blue;

        >img {
          background-color: $hov-blue;
        }
      }

      &.hov-pink {
        background-color: $hov-pink;
        >img {
          background-color: $hov-pink;
        }
      }

      &.hov-gray {
        background-color: $hov-gray;
        >img {
          background-color: $hov-gray;
        }
      }
    }

    .active {
      .description {
        opacity: 1;
      }

      .img-max-size {
        transform: translateX(-50%) translateY(-50%) scale(1.05);
      }
    }

    

    @include respond-to("onlydesktop") {
      //pointer-events: none;

      &:hover {
        .description {
          opacity: 1;
        }
  
        .img-max-size {
          transform: translateX(-50%) translateY(-50%) scale(1.05);
        }
      }
    }
  }

  .full {
    position: relative;
    width: calc(100% - #{$gap/3});
    height: 50vh;
    margin-bottom: $gap/2;
    overflow: hidden;
    margin-right: $gap/6;
    margin-left: $gap/6;

    &-text {
      width: 100%;
      height: auto;
      padding: $gap-s 0 0;

      margin-right: $gap/6;
      margin-left: $gap/6;

      line-height: 1.4;
      letter-spacing: .5px;
    }

    .description {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: $gap;
      background-color: #fff;
      opacity: 0;
      box-shadow: 0 0 0 1px #fff;
      transition: opacity 0.4s ease;
      z-index: 1;

      > h3 {
        margin-bottom: $gap/4;
      }

      img {
        max-width: 185px;
      }
      
      > p {
        margin: 0;
      }
    }

    .active {
      .description {
        opacity: 1;
      }     

      .img-max-size {
        transform: translateX(-50%) translateY(-50%) scale(1.05);
      }
    }

    @include respond-to("onlydesktop") {
      //pointer-events: none;

      &:hover {
        .description {
          opacity: 1;
        }
  
        .img-max-size {
          transform: translateX(-50%) translateY(-50%) scale(1.05);
        }
      }
    }
   
  }

  @include respond-to("tablet") {
    flex-direction: column;

    .half {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: $gap;

      &:nth-of-type(odd) {
        margin-right: 0;
      }

      &:nth-of-type(even) {
        margin-left: 0;
      }
    }
  }
}
