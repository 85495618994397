//! import google fonts
// @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
// OR use @font-face like below

//! import a custom font below



@font-face {
  font-family: 'Manrope Light';
  font-display: fallback;
  font-style: normal;
  font-weight: 100;
  src:
    local('Manrope Light'), /* Checks if font is on users computer */
    local('Manrope-Light'), /* Checks if font is on users computer */
    url('fonts/Manrope-Light.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}

@font-face {
  font-family: 'Manrope Medium';
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src:
    local('Manrope Medium'), /* Checks if font is on users computer */
    local('Manrope-Medium'), /* Checks if font is on users computer */
    url('fonts/Manrope-Medium.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}

@font-face {
  font-family: 'Manrope Bold';
  font-display: fallback;
  font-style: normal;
  font-weight: 600;
  src:
    local('Manrope Bold'), /* Checks if font is on users computer */
    local('Manrope-Bold'), /* Checks if font is on users computer */
    url('fonts/Manrope-Bold.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}

@font-face {
  font-family: 'Manrope Extra Bold';
  font-display: fallback;
  font-style: normal;
  font-weight: 800;
  src:
    local('Manrope ExtraBold'), /* Checks if font is on users computer */
    local('Manrope-ExtraBold'), /* Checks if font is on users computer */
    url('fonts/Manrope-ExtraBold.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}

/* Manrope-Medium.woff2
Manrope-SemiBold.woff2
Manrope-Bold.woff2
Manrope-Light.woff2
Manrope-Regular.woff2
Manrope-ExtraBold.woff2
Manrope-ExtraLight.woff2 */

//! set font variables
$font-heading: 'Manrope Bold', Helvetica, Arial, sans-serif;
$font-body: 'Manrope Medium', Helvetica, Arial, sans-serif;
$font-light: 'Manrope Light', Helvetica, Arial, sans-serif;
$font-extra-bold: 'Manrope Extra Bold', Helvetica, Arial, sans-serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: 1.6rem;
  color: $black;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading, serif;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: initial;
}

h1 {
  font-size: 3.2rem;
  font-family: 'Manrope Light';
  line-height: 5rem;

  @include respond-to("tablet") {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}
h2 {
  font-size: 2.5rem;
  font-family: 'Manrope Light';
  line-height: 60px;
}
h3 {
  font-size: 1.872rem;
}
h4 {
  font-size: 1.6rem;
}
h5 {
  font-size: 1.328rem;
}
h6 {
  font-size: 1.072rem;
}

p {
  font-size: 1.2rem;
  line-height: 1.7rem;
}

a {
  color: #000;

  &.link-content {
    color: $color-blue;
  }
}