img {
    &.img {
        &-responsive {
            max-width: 100%;
            height: auto;
        }
        &-bordered {
            border: 1px solid rgb(233, 233, 233);
            background-color: rgb(248, 244, 239);
            padding: 10px;
        }        
        &-shadowed {
            box-shadow: 0 15px 15px rgba(0, 0, 0, 0.25);
        }
        &-rounded {
            border-radius: 20px;
            overflow: hidden;
        }
        &-round {
            border-radius: 999px;
            overflow: hidden;
        }

        &-max-size {
            position: absolute;            
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) scale(1);
            transition: transform .4s ease;
        }
    }
}