*::selection {
  background: $pink;
}

html,
body {
  height: 100%;
  font-size: 16px;
}

body {
  width: 100%;
  min-height: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.holder-images {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;

  img {
    flex: 0 1 auto;
    display: inline;
  }
}
